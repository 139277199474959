import React from "react"
import Img from "gatsby-image"
import styled, { css } from "styled-components"
import MediumImage from "gatsby-image"

// heroHeading
// heroParagraph
// heroImage

export default ({ data, heroHeading, heroParagraph, heroImage, children }) => (
  <HeroBackground>
    <ImageContainer
      className="container align-items-center"
      heroImageBackground={heroImage}
    >
      <InnerImageContainer className="container boxed-width justify-content-center">
        <TextColumn className="col-6 col-12-t">
          <HeroTextContainer>
            <HeroHeading>{heroHeading}</HeroHeading>
            <HeroPara>{heroParagraph}</HeroPara>
            <div className="container">{children}</div>
          </HeroTextContainer>
        </TextColumn>
      </InnerImageContainer>
    </ImageContainer>
  </HeroBackground>
)

// Styled components
const HeroBackground = styled.div`
  background-color: #1a1a1a;
  min-height: 400px;
  width: 100%;
  display: flex;
`

const ImageContainer = styled.div`
  padding-top: 256px;
  padding-bottom: 256px;
  position: relative;

  @media (max-width: 768px) {
    padding-bottom: 160px;
  }

  > div {
    display: flex;
    align-items: flex-start;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 1;
    background: ${props =>
      `url(${props.heroImageBackground}) no-repeat center center`};
    background-size: cover;
    opacity: 0.72;
  }
`

const InnerImageContainer = styled.div`
  position: relative;
  z-index: 2;
  opacity: 1;
`

const NormalImage = styled.img`
  height: auto;
  max-width: 282px;
  object-fit: contain;
  object-position: center;
  position: relative;
  z-index: 2;
  border-radius: 10px;
`
const TextColumn = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const HeroTextContainer = styled.div`
  text-align: center;
`

const HeroEmojiSpan = styled.span`
  padding: 0 0px;
`

const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`

const HeroHeading = styled.h1`
  color: #fff;
`

const HeroPara = styled.p`
  color: #fff;
  max-width: 66%;
  font-size: 16px;
  margin: 0 auto;
`

const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
`

const CtaBox = styled.div`
  background-color: #1a4252;
  color: #fff;
  border-radius: 8px;
  padding: 48px;
  margin-bottom: 24px;

  h2 {
    font-size: 24px;
  }

  h2,
  p {
    color: #fff;
  }
`

const TextButton = styled.a`
  font-size: 24px;
  color: #fff;
  text-decoration: none;
  font-weight: 600;
`
