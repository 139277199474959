import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import Hero from "../components/hero-components/hero.js"
import AboutUs from "../components/text-components/about-us.js"
import IntroCta from "../components/text-components/intro-cta.js"
import Testimonial from "../components/hero-components/testimonials.js"
import Process from "../components/text-components/flooring-process.js"
import Products from "../components/text-components/products.js"
import CTA from "../components/hero-components/call-to-action.js"

const IndexPage = () => (
  <Layout>
    <Hero
      heroHeading="Flooring to make your house a home"
      heroParagraph="A family run flooring company, here to help you with all of your flooring needs."
      heroImage="/content/hero-1.png"
    />

    <AboutUs
      aboutHeading="At Cannadines Carpets we provide a wide variety of flooring and installation services throughout Sussex."
      aboutParagraph="Choosing new flooring can be a daunting task. But here at Cannadines Carpets we provide you with an honest and helpful service, and guide you through your flooring journey."
      aboutImage="/content/team.png"
    />

    <IntroCta
      aboutHeading="Use our online booking system to arrange for a measure and view our sample selection"
      aboutParagraph="Choosing your flooring at home allows you to see samples against natural light and your own decor, making that final decision a little easier."
      aboutImage="/content/intro-cta.png"
    />

    <Process />

    <Testimonial
      testimonialName="Carol Smith"
      testimonialText="“I cannot rate these guys highly enough. Fantastic customer service from Clare, and Mark's worksmanship was flawless. We are over the moon with our new flooring - brilliant. Thank you Cannadines Carpets ❤️”"
      testimonialWorkText="The entire house was supplied and fitted with new carpet."
      heroImage="/content/testimonials.png"
    />

    <Products />

    <CTA
      ctaHeading="Book yourself a measure"
      ctaParagraph="Use our online booking system and arrange for a home call with one of our amazing flooring experts."
      heroImage="/content/cta.png"
    />

    <div className="call-to-action"></div>
    <div className="footer"></div>
  </Layout>
)

export default IndexPage
