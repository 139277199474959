import React from "react"
import Img from "gatsby-image"
import styled, { css } from "styled-components"
import MediumImage from "gatsby-image"
import StyledLinkButton from "../common/styled-link-button.js"

export default ({ data, aboutHeading, aboutParagraph, aboutImage }) => (
  <div>
    <ImageContainer className="container align-items-center">
      <div className="container boxed-width justify-content-space-between align-items-center">
        <PanelContainer className="col-6 col-12-t">
          {aboutImage ? <NormalImage src={aboutImage} /> : ""}
        </PanelContainer>

        <TextColumn className="col-5 col-12-t">
          <HeroTextContainer>
            <AboutHeading>{aboutHeading}</AboutHeading>
            <AboutPara>{aboutParagraph}</AboutPara>
            <StyledLinkButton to="/bookings/">
              Book a home visit
            </StyledLinkButton>
          </HeroTextContainer>
        </TextColumn>
      </div>
    </ImageContainer>
  </div>
)

// Styled components
const HeroBackground = styled.div`
  background-color: #1a1a1a;
  min-height: 400px;
  width: 100%;
  display: flex;
`

const ImageContainer = styled.div`
  padding-bottom: 160px;

  @media (max-width: 768px) {
    padding-bottom: 120px;
  }
`

const NormalImage = styled.img`
  object-fit: contain;
  object-position: center;
  position: relative;
  z-index: 2;

  @media (max-width: 768px) {
    width: 100%;
  }
`
const TextColumn = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
`

const HeroTextContainer = styled.div`
  text-align: left;
  position: relative;
`

const HeroEmojiSpan = styled.span`
  padding: 0 0px;
`

const PanelContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
    order: 2;
    padding-top: 24px;
  }
`

const AboutHeading = styled.h2`
  font-size: 24px;
  line-height: 36px;
  color: #1a4252;
`

const AboutPara = styled.p``

const OurFamily = styled.img`
  position: absolute;
  right: -10%;
  max-width: 215px;
`
